import * as React from "react"
import EmailLink from "../components/email-link"

import Layout from "../components/layout"

const PrivacyPage = () => {
  return (
    <Layout title="Privacy Policy">
      <main className="mt-8 pt-8 mb-16">
        <h1 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
          Privacy Policy
        </h1>
        <article class="wrapper-condensed" role="contentinfo">
          <div className="border-t-2 border-yellow-500 my-12"></div>
          <h2>Who we are:</h2>
          <p>Our website address is: https://sbrelocations.uk</p>
          <div className="border-t-2 border-yellow-500 my-12"></div>
          <h2>Your Personal Data:</h2>
          <h3>What data do we need?</h3>
          <p>
            SB Relocations Limited (also referred to herein as We) is what’s
            known as the ‘Controller’ of the personal data you provide to us. We
            try and keep data collection to a minimum and only collect basic
            personal data which does not include any special types of
            information or location-based information unless a physical product
            is purchased. This does, however, include name, address, telephone
            number, email, cookies.
          </p>
          <h3>Why do we need it?</h3>
          <p>
            We need to know your basic personal data to provide you with
            information about the SB Relocations Limited training services and
            products. We will not collect any personal data from you we do not
            need to provide and oversee this service to you.
          </p>
          <h3>What do we do with it?</h3>
          <p>
            All the personal data we process is processed by our staff in the UK
            however for the purposes of IT hosting and maintaining this
            information is located on servers within the European Union or the
            US. You can unsubscribe at any time via email.
          </p>
          <p>
            All data relating to you is kept on{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacy.google.com/businesses/compliance/#?modal_active=none"
            >
              Gmail{" "}
            </a>
            or{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://privacy.google.com/businesses/compliance/#?modal_active=none"
            >
              GoogleDrive
            </a>
            .
          </p>
          <h3>How long do we keep it?</h3>
          <p>
            Under a contractual basis, we are required under UK tax law to keep
            your basic personal data (name, address, contact details) for a
            minimum of 7 years after which time it will be destroyed. The
            information we use for marketing purposes will be kept with us until
            you notify us that you no longer wish to receive this information.
          </p>
          <h3>What are your rights?</h3>
          <p>
            If at any point you believe the information we process on you is
            incorrect you can request to see this information and have it
            corrected or deleted. If you are not satisfied with how we process
            your personal data in accordance with the law, you can complain to
            the Information Commissioner’s Office (ICO).
          </p>
          <p>
            <strong>Notice</strong> — data subjects should be given notice when
            their data is being collected; email, name and telephone number only
            in most cases although, postal addresses may be collected for
            physical products.
          </p>
          <p>
            <strong>Purpose</strong> — data should only be used for the purpose
            stated and not for any other purposes; property and business growth
            training and newsletters and physical products.
          </p>
          <p>
            <strong>Security</strong> — collected data should be kept secure
            from any potential abuses; data is secured by double password and
            recognised email providers.
          </p>
          <p>
            <strong>Access</strong> — data subjects should be allowed to access
            their data and make corrections to any inaccurate data; Your data
            may be updated or changed at your request.
          </p>
          <p>
            We map data flows and hold information compliant with ICO
            regulations as a data controller.
          </p>
          <div className="border-t-2 border-yellow-500 my-12"></div>
          <h2>Embedded content from other websites</h2>
          <p>
            Articles on this site may include embedded content (e.g. videos,
            images, articles, etc.). Embedded content from other websites
            behaves in the exact same way as if the visitor has visited the
            other website.
          </p>
          <p>
            These websites may collect data about you, use cookies, embed
            additional third-party tracking, and monitor your interaction with
            that embedded content, including tracking your interaction with the
            embedded content if you have an account and are logged in to that
            website.
          </p>
          <div className="border-t-2 border-yellow-500 my-12"></div>
          <h2>Contact Information for Complaints or Concerns</h2>
          <p>
            If you have any complaints or concerns about the Company or about
            this privacy statement, please contact:
          </p>
          <p>
            Via email:{" "}
            <EmailLink />
          </p>
          <p>
            Information provided by you via general email inquiries to the
            Company such as your e-mail address is used only to respond to your
            inquiries in the ordinary course of business and is never shared
            with third parties.
          </p>
        </article>
      </main>
    </Layout>
  )
}

export default PrivacyPage
