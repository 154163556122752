import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

const EmailLink = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            email
          }
        }
      }
    `
  )

  // function decode(s) {
  //   var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
  //   var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
  //   for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
  //   for (x = 0; x < L; x++) {
  //       c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
  //       while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
  //   }
  //   return r;
  // }

  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => onMount());

  function onMount() {
    // setEmail(() => decode(props.email ? props.email : site.siteMetadata?.email || 'no-email@provided.com'), []);
    setEmail(props.email ? props.email : site.siteMetadata?.email || 'no-email@provided.com');
    setTitle(props.title ? props.title : email);
  }

  return (
    <a href={`mailto:${email}`} className={props.className}>
      {title}
    </a>
  )
}

EmailLink.propTypes = {
  email: PropTypes.string,
  title: PropTypes.string,
}

// EmailLink.defaultProps = {
//   title: "",
//   email: "",
// }

export default EmailLink
